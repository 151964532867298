import Vue from 'vue'
import { decode } from '@/lib/util'
import '../product-card/product-card.vue'

export default el => new Vue({
  name: 'Featured Product',
  el,
  data() {
    const initialProduct = el.getAttribute('initial-product')
    return {
      product: JSON.parse(decode(initialProduct)) || null
    }
  },
})